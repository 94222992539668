import React from 'react';
import { Box, Heading, Text, Container, VStack, Button, useColorModeValue, Flex, Divider, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';
import { Helmet } from 'react-helmet';

const BlogPost = ({ title, excerpt, date }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Box mb={10}>
      <Text fontSize="sm" color="gray.500" mb={2}>{date}</Text>
      <Heading as="h3" size="lg" mb={2} color={textColor}>
        {title}
      </Heading>
      <Text mb={4} color={textColor}>{excerpt}</Text>
      <Button
        colorScheme="purple"
        size="sm"
        fontWeight="bold"
        px={4}
        borderRadius="full"
        _hover={{
          transform: 'translateY(-2px)',
          boxShadow: 'md',
        }}
      >
        {t("Read More")}
      </Button>
      <Divider mt={8} />
    </Box>
  );
};

const Blog = () => {
  const { t } = useTranslation();
  const bgColor = 'black';
  const textColor = 'gray.100';

  const blogPosts = [
    {
      title: t("The Future of Customer Service: AI-Powered Phone Agents"),
      excerpt: t("Explore how AI is revolutionizing the customer service industry, providing 24/7 support and personalized experiences."),
      date: "June 1, 2024"
    }
  ];

  return (
    <Box bg={bgColor} color={textColor} minH="100vh">
      <Helmet>
        <title>VoxAI Blog - Insights on AI-Powered Customer Service</title>
        <meta name="description" content="Stay updated with the latest trends in AI-powered customer service. Explore VoxAI's blog for insights, tips, and success stories on revolutionizing customer interactions." />
        <link rel="canonical" href="https://voxai.app/blog" />
        <meta property="og:title" content="VoxAI Blog - Insights on AI-Powered Customer Service" />
        <meta property="og:description" content="Stay updated with the latest trends in AI-powered customer service. Explore VoxAI's blog for insights, tips, and success stories on revolutionizing customer interactions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://voxai.app/blog" />
        <meta property="og:image" content="https://voxai.app/blog-og-image.jpg" />
        <meta property="og:site_name" content="VoxAI" />
      </Helmet>
      <Container maxW="container.md" py={20}>
        <VStack spacing={10} align="stretch">
          <Flex justifyContent="center" mb={6}>
            <RouterLink to="/">
              <Logo size="4xl" text="VoxAI" />
            </RouterLink>
          </Flex>
          <Box textAlign="center">
            <Heading as="h1" size="2xl" mb={4}>
              {t("VoxAI Blog")}
            </Heading>
            <Text fontSize="xl">
              {t("Insights, updates, and stories about AI-powered customer service")}
            </Text>
          </Box>

          {blogPosts.map((post, index) => (
            <BlogPost key={index} {...post} />
          ))}

          <Box textAlign="center">
            <Button
              colorScheme="purple"
              size="lg"
              fontWeight="bold"
              px={8}
              borderRadius="full"
              _hover={{
                transform: 'translateY(-2px)',
                boxShadow: 'lg',
              }}
            >
              {t("Load More Posts")}
            </Button>
          </Box>
        </VStack>
      </Container>

      <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
        <Container maxW="container.xl">
          <Flex direction={["column", "row"]} justify="space-between" align="center">
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
              <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
              <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
              <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
            </VStack>
            <VStack align="flex-start" mb={[4, 0]}>
              <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
              <Link as={RouterLink} to="/documentation" color="gray.500" _hover={{ color: "white" }}>{t("Documentation")}</Link>
              <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
            </VStack>
            <VStack align="flex-start">
              <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
              <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
            </VStack>
          </Flex>
          <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {t("Your Company Name")}. {t("All rights reserved.")}
          </Text>
        </Container>
      </Box>
    </Box>
  );
};

export default Blog;
