import React from 'react';
import { Box, Heading, Text, Container, VStack, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Button, Flex, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../components/Logo';

const DocumentationSection = ({ title, content }) => (
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left" fontWeight="bold">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      {content}
    </AccordionPanel>
  </AccordionItem>
);

const Documentation = () => {
  const { t } = useTranslation();
  const bgColor = 'black';
  const textColor = 'gray.100';

  return (
    <Box bg={bgColor} color={textColor} minH="100vh" display="flex" flexDirection="column">
      <Container maxW="container.xl" py={20} flex="1">
        <VStack spacing={8} align="stretch">
          <Flex justifyContent="center" mb={6}>
            <RouterLink to="/">
              <Logo size="4xl" text="VoxAI" />
            </RouterLink>
          </Flex>
          <Heading as="h1" size="2xl" textAlign="center" mb={4}>
            {t("VoxAI Documentation")}
          </Heading>
          
          <Box 
            bg="yellow.500" 
            color="black" 
            p={3} 
            borderRadius="md" 
            textAlign="center" 
            mb={6}
          >
            <Text fontWeight="bold">
              {t("🚧 Under Construction 🚧")}
            </Text>
            <Text fontSize="sm">
              {t("We're working hard to bring you comprehensive documentation. Check back soon!")}
            </Text>
          </Box>
          
          <Text fontSize="lg" textAlign="center" mb={10}>
            {t("Welcome to the VoxAI documentation. Here you'll find everything you need to know about setting up and using our AI-powered phone agents.")}
          </Text>

          <Accordion allowMultiple>
            <DocumentationSection
              title={t("Getting Started")}
              content={
                <VStack align="stretch" spacing={4}>
                  <Text>{t("To get started with VoxAI, follow these steps:")}</Text>
                  <Text>1. {t("Sign up for an account")}</Text>
                  <Text>2. {t("Set up your first AI agent")}</Text>
                  <Text>3. {t("Configure your phone system integration")}</Text>
                  <Text>4. {t("Test your AI agent")}</Text>
                  <Button
                    as="a"
                    href="#"
                    colorScheme="purple"
                    size="sm"
                    fontWeight="bold"
                    px={4}
                    borderRadius="full"
                    _hover={{
                      transform: 'translateY(-2px)',
                      boxShadow: 'md',
                    }}
                  >
                    {t("Read full guide")}
                  </Button>
                </VStack>
              }
            />
            
            <DocumentationSection
              title={t("AI Agent Configuration")}
              content={
                <VStack align="stretch" spacing={4}>
                  <Text>{t("Learn how to configure your AI agents for optimal performance:")}</Text>
                  <Text>• {t("Personality settings")}</Text>
                  <Text>• {t("Language models")}</Text>
                  <Text>• {t("Custom knowledge bases")}</Text>
                  <Text>• {t("Response templates")}</Text>
                  <Link color="blue.500" href="#">{t("Read full guide")}</Link>
                </VStack>
              }
            />
            
            <DocumentationSection
              title={t("Integration Guide")}
              content={
                <VStack align="stretch" spacing={4}>
                  <Text>{t("Integrate VoxAI with your existing systems:")}</Text>
                  <Text>• {t("VoIP systems")}</Text>
                  <Text>• {t("CRM platforms")}</Text>
                  <Text>• {t("Scheduling software")}</Text>
                  <Text>• {t("Custom APIs")}</Text>
                  <Link color="blue.500" href="#">{t("Read full guide")}</Link>
                </VStack>
              }
            />
            
            <DocumentationSection
              title={t("Analytics and Reporting")}
              content={
                <VStack align="stretch" spacing={4}>
                  <Text>{t("Understand your AI agents' performance:")}</Text>
                  <Text>• {t("Call statistics")}</Text>
                  <Text>• {t("Sentiment analysis")}</Text>
                  <Text>• {t("Conversion rates")}</Text>
                  <Text>• {t("Custom reports")}</Text>
                  <Link color="blue.500" href="#">{t("Read full guide")}</Link>
                </VStack>
              }
            />
            
            <DocumentationSection
              title={t("Troubleshooting")}
              content={
                <VStack align="stretch" spacing={4}>
                  <Text>{t("Common issues and their solutions:")}</Text>
                  <Text>• {t("Connection problems")}</Text>
                  <Text>• {t("Audio quality issues")}</Text>
                  <Text>• {t("AI response accuracy")}</Text>
                  <Text>• {t("Integration errors")}</Text>
                  <Link color="blue.500" href="#">{t("Read full guide")}</Link>
                </VStack>
              }
            />
          </Accordion>

          <Box textAlign="center" mt={10}>
            <Text fontSize="lg" fontWeight="bold">
              {t("Need more help?")}
            </Text>
            <Text>
              {t("Contact our support team at")} <Link color="blue.500" href="mailto:support@voxai.app">support@voxai.app</Link>
            </Text>
          </Box>
        </VStack>
      </Container>
      <Footer />
    </Box>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box as="footer" bg="gray.900" color="gray.300" py={8} mt={20}>
      <Container maxW="container.xl">
        <Flex direction={["column", "row"]} justify="space-between" align="center">
          <VStack align="flex-start" mb={[4, 0]}>
            <Text fontWeight="bold" mb={2}>{t("Company")}</Text>
            <Link as={RouterLink} to="/about" color="gray.500" _hover={{ color: "white" }}>{t("About Us")}</Link>
            <Link as={RouterLink} to="/contact" color="gray.500" _hover={{ color: "white" }}>{t("Contact")}</Link>
            <Link as={RouterLink} to="/privacy-policy" color="gray.500" _hover={{ color: "white" }}>{t("Privacy Policy")}</Link>
          </VStack>
          <VStack align="flex-start" mb={[4, 0]}>
            <Text fontWeight="bold" mb={2}>{t("Resources")}</Text>
            <Link as={RouterLink} to="/documentation" color="gray.500" _hover={{ color: "white" }}>{t("Documentation")}</Link>
            <Link as={RouterLink} to="/blog" color="gray.500" _hover={{ color: "white" }}>{t("Blog")}</Link>
          </VStack>
          <VStack align="flex-start">
            <Text fontWeight="bold" mb={2}>{t("Connect")}</Text>
            <Link href="#" color="gray.500" _hover={{ color: "white" }}>X</Link>
          </VStack>
        </Flex>
        <Text mt={8} textAlign="center" fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} {"voxai"}. {t("All rights reserved.")}
          </Text>
      </Container>
    </Box>
  );
};

export default Documentation;
