import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import VerificationSuccessPage from './pages/VerificationSuccessPage';
import Help from './Help';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Documentation from './pages/Documentation';
import Blog from './pages/Blog';

function App() {
  return (
    <ChakraProvider theme={{
      ...theme,
      config: {
        ...theme.config,
        initialColorMode: 'dark',
        useSystemColorMode: false,
      },
    }}>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/verification-success" element={<VerificationSuccessPage />} />
          <Route path="/help" element={<Help />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/blog" element={<Blog />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
